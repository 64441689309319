import React, { useEffect, useState } from "react"
import queryString from "query-string"
import BlogPage from "../{microcmsBlog.slug}"

const Draft = ({ location }) => {
  const { contentId, draftKey } = queryString.parse(location.search)
  const [data, setData] = useState(null)
  useEffect(() => {
    fetch(
      `https://${process.env.GATSBY_MICROCMS_ID}.microcms.io/api/v1/blog/${contentId}?draftKey=${draftKey}`,
      {
        headers: {
          "X-API-KEY": process.env.API_KEY,
        },
      }
    )
      .then(res => res.json())
      .then(res => setData({ microcmsBlog: res })) // ※1
  }, [contentId, draftKey]) // ※2

  if (data === null) {
    return null
  }
  return <BlogPage data={data} />
}

export default Draft
